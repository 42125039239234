export const colors = {
  background: '#222',
  secondary: '#9d9d9d',
  white: '#fff'
};

export const colorUsage = {
  tileBorder: colors.white,
  hoverTileBorder: colors.secondary
};
